.life-insurance-101 {
  & .home-hero-image div{
    height: 70vh;
  }
}

.card {
 background-image: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20500%20100%22%20className=%22wave%22%20height=%2250%22%3E%3Cpath%20d=%22M%200%2010%20C%20150%2040%20180%20-5%20500%2030%20L%20500%200%20L%200%200%22%20fill=%22rgb(0,%2096,%20100)%22%3E%3C/path%3E%3C/svg%3E");
 background-repeat: no-repeat;
 background-size: cover;
}

.wave {
  position: absolute;
}