.app--logo {
  padding: 4px;
  
  a {
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;

    img, svg {
      max-width: 40px;
      height: auto;
    }
  }
}